import React from 'react';
import config from '../../config';

 function Footer() {
  return (
    <footer className="top80">
      <ul className="actions special">
        <li>SÍGUENOS</li>
      </ul>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon alt ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
}

export default React.memo(Footer)
