import React from 'react';
import { useSpring, animated } from 'react-spring';

import logo from '../assets/images/ERA_GRIS.png';
import { Link } from 'gatsby';
import { navigate } from "gatsby";

export default React.memo(
  function Splash() {

  const props = useSpring({
    to: { opacity: 1, x: 1 },
    from: { opacity: 0, x: 0 }
  });
/**
 * 
 *     
 * 
 */
  return (
<animated.section
      id="header"
      style={{
        transform: props.x
          .to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
          })
          .to(x => `scale(${x})`),
          position: 'absolute',
          //top: '10vh',
          //left: '15vw',
          display: 'block',
          zIndex: 1,
        ...props
      }}
      onClick={e => navigate(`/Home`)}
    >
      <header className="major" style={{
            zIndex: 100,
            position: 'relative'
          }}  onClick={e => navigate(`/Home`)}>
        <img
          className="major-logo"
          src={`${logo}`}
          alt="logo era"
          style={{
            zIndex: 100,
            transition: 'transform .5s ease-out',
            transform: 'scale(0.75, 0.9)',
            position: 'relative'
          }}

          onMouseOver = { e => {
            let clientRect = e.target.getBoundingClientRect();
            let threshold = 15;
              /*  console.log(
              'onMouseOver target',
              e.target.getBoundingClientRect(),
              e.pageX,
              e.pageY,
              clientRect.right - e.pageX,
              clientRect.top - e.pageY
            );*/
            e.preventDefault();
            if (
              (clientRect.right - e.pageX > threshold ||
                clientRect.left - e.pageX > threshold) &&
              (clientRect.top - e.pageY > threshold ||
                clientRect.bottom - e.pageY > threshold)
            ) {
              //  e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.transform =               'scale(1.05)';
            }
          }}

          onMouseOut = { e => {
            e.preventDefault();
            let clientRect = e.target.getBoundingClientRect();
            let threshold = 5;

            /*console.log(
              'onMouseOut target',
              e.target.getBoundingClientRect(),
              e.pageX,
              e.pageY,
              clientRect.right - e.pageX,
              clientRect.top - e.pageY
            );*/
            if (
              (clientRect.right - e.pageX <= threshold ||
                clientRect.left - e.pageX <= threshold) &&
              (clientRect.top - e.pageY <= threshold ||
                clientRect.bottom - e.pageY <= threshold)
            ) {
              //  e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.transform =             'scale(1)';
            }
          }}

          onMouseMove = { e => {
            e.preventDefault();

            let clientRect = e.target.getBoundingClientRect();
            let threshold = 17;

            if (
              (clientRect.right - e.pageX > threshold ||
                clientRect.left - e.pageX > threshold) &&
              (clientRect.top - e.pageY > threshold ||
                clientRect.bottom - e.pageY > threshold)
            ) {
              let clientRect = e.target.getBoundingClientRect();

              let xmove =
                ((e.pageX - clientRect.left) / clientRect.width) * 100;
              let ymove =
                ((e.pageY - clientRect.top) / clientRect.height) * 100;

               /* console.log(
                  'onMouseMove target',
                  e.target.getBoundingClientRect(),
                  {xmove, ymove}
                );*/

              e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.transform =
                'scale(1.0175)';
              e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.transformOrigin =
                //xmove / 3 + '% ' + ymove / 3 + '%';
                xmove / 4 + '% ' + ymove / 4 + '%';

              /*  e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.position =
                'fixed';
              e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.left =
                ymove + '%';*/

             // e.target.style.position = 'fixed';
              e.target.style.left = clientRect.width / 15 + (-1 * xmove) / 5 + 'px';
                //-clientRect.width / 2 + (-1 * xmove) / 8 + 'px';
              e.target.style.top = clientRect.height / 15 + (-1 * ymove) / 5 + 'px';
                //-clientRect.height / 2 + (-1 * ymove) / 8 + 'px';

              /*  e.target.style.transform =
                'translate(' +
                (-1 * xmove) / 5 +
                'px,' +
                (-1 * ymove) / 5 +
                'px)';*/
            }
          }
        }
        onClick={e => navigate(`/Home`)}
        />
        {' '}
        <div className="" style={{zIndex:'199' , position: 'relative'}} onClick={e => navigate(`/Home`)}>
          <Link to="/Home" activeStyle={{zIndex:'199', ...props}}>
            {' '}
            ENTRAR{' '}
          </Link>
      </div>
    </header>
    </animated.section>
  );
}
)
//  <h1> ESPERA PRONTO NUESTRA NUEVA PÁGINA WEB</h1>
