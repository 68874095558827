import React from 'react';
import Layout from '../components/Layout';
import Splash from '../components/Splash';
import Footer from '../components/Footer';

const IndexPage = () => (
  <Layout  >
    <div className="main-body" >
      <Splash />
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
